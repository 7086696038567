import { FC } from 'react';

import { Link } from 'gatsby';

interface Props {
  href?: string;
  small?: boolean;
  active?: boolean;
  className?: string;
}

const LinkButton: FC<Props> = ({ href = '', children, small, active, className }) => (
  <Link
    className={className || `sign-up-btn${small ? ' small' : ''} ${active ? 'active' : ''}`}
    to={href}
    role='button'
  >
    {children}
  </Link>
);

export default LinkButton;
